<template>
  <div style="display: flex; height: 100vh; align-items: center">
    <div class="container">
      <div class="background"></div>
      <div class="row m-md-5 no-gutters shadow-lg rounded overflow-hidden">
        <div class="col-md-6 d-none d-md-block">
          <img
            :src="require('@/assets/loginImage.jpg')"
            class="img-fluid"
            style="height: 100%; object-fit: cover; object-position: left"
          />
        </div>
        <div class="col-md-6 bg-white p-5">
          <v-img
            :src="require('@/assets/LogoVerbum.png')"
            max-width="200"
            class="m-auto my-5"
            contain
          ></v-img>
          <!-- <h4 class="pb-3">Login</h4> -->
          <div class="form-style">
            <form @submit.prevent="login">
              <div class="form-group pb-3">
                <input
                  type="email"
                  placeholder="Email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  v-model="username"
                  required
                />
              </div>
              <div class="form-group pb-3">
                <input
                  type="password"
                  placeholder="Password"
                  class="form-control"
                  id="exampleInputPassword1"
                  v-model="password"
                  required
                />
              </div>
              <div class="pb-2">
                <v-btn class="btn-style m-0" type="submit" block large
                  >Submit</v-btn
                >
              </div>
            </form>
            <div class="sideline">OR</div>
            <div>
              <v-btn
                href="mailto:info@verbumscript.app"
                block
                large
                color="primary"
                >Contact us for demo</v-btn
              >
            </div>
            <!-- <div class="pt-4 text-center">
            You don't have account? <a href="#">Contact us</a>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("autentification/login", {
          email: this.username,
          password: this.password,
        });
        // Ovde možete dodati redirekciju na željenu stranicu nakon prijave
      } catch (error) {
        alert(error.message); // Prikazivanje poruke o grešci
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters("autentification", ["isLoggedIn", "getUser"]),
    userStatus() {
      return this.getUser ? this.getUser.status : null;
    },
  },
  mounted() {
    console.log("User info:", this.userStatus);
  },
};
</script>

<style scoped>
.form-style input {
  border: 0;
  height: 50px;
  border-radius: 0;
  border-bottom: 1px solid #ebebeb;
}
.form-style input:focus {
  border-bottom: 1px solid #007bff;
  box-shadow: none;
  outline: 0;
  background-color: #ebebeb;
}
.sideline {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ccc;
}
button {
  height: 50px;
}
.sideline:before,
.sideline:after {
  content: "";
  border-top: 1px solid #ebebeb;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}
.sideline:after {
  margin: 0 0 0 20px;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(93.96deg, #5d5fef 6.46%, #0dcaf0 98.85%);
}
</style>
