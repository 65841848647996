import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Pusher from 'pusher-js';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import notificationMixin from './mixin/notificationMixin';

export const notify = (message, type) => {
  Vue.$toast(message, { type: type });
};

const toastOptions = {
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
};

Vue.mixin(notificationMixin);

Vue.use(Toast, toastOptions);

Vue.prototype.$bus = new Vue();

Vue.prototype.$globalStore = {
  pusherData: null
};

// Inicijalizacija Pusher-a i povezivanje na kanal
Pusher.logToConsole = true;
const pusher = new Pusher('2fa2f431d046daeee56d', {
  cluster: 'eu',
  encrypted: true
});
const channel = pusher.subscribe('my-channel');

// Povezivanje na događaj koji prima podatke
channel.bind('my-event', function (newTranscription) {
  console.log('Podaci primljeni sa Pusher-a:', newTranscription);
  Vue.prototype.$bus.$emit('pusher-data-received', newTranscription);

  // Provjeri da li postoji 'message' u objektu 'newTranscription'
  if (newTranscription.message) {
    // Proveri da li postoji user objekat u local storage-u
    const storedUser = JSON.parse(localStorage.getItem("user"));

    // Ako postoji user objekat i ima ID
    if (storedUser && storedUser.id && storedUser.id == newTranscription.message.user_id) {
      if (newTranscription.message.logout === true) {
        // Obriši local storage
        localStorage.removeItem("user");

        // Osvježi stranicu
        location.reload();
      }
    }
  }
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
