import { notify } from "@/main";
import axios from 'axios';

const state = {
    isLoggedIn: false,
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
};

const mutations = {
    login(state, { token, user }) {
        state.isLoggedIn = true;
        state.token = token;
        state.user = user;

        localStorage.setItem('user', JSON.stringify(user));
    },
    logout(state) {
        state.isLoggedIn = false;
        state.token = null;
        state.user = null;

        localStorage.removeItem('user');
    },
};

const actions = {
    async login({ commit }, { email, password }) {
        try {
            const response = await axios.post(
                "https://verbumscript.app/api/frontend/login",
                {
                    email,
                    password,
                    token: "test",
                }
            );
            notify("Welcome!", "success");
            commit("login", { token: response.data.token, user: response.data.response.user });
            window.location.reload();
        } catch (error) {
            notify(
                "Login failed, please try again or contact support!",
                "error"
            );
            console.log(error);
        }
    },
    async logout({ commit }) {
        const userID = JSON.parse(localStorage.getItem("user")).id;
        console.log(userID, "ajmoo");
        try {
            await axios.post("https://verbumscript.app/api/frontend/logout", {
                userId: userID,
                token: "test",
            });
            notify("Logout success", "success");
            commit("logout");
        } catch (error) {
            notify("Failed", "error");
            console.error("Error:", error);
        }
    },
};

const getters = {
    isLoggedIn: (state) => state.isLoggedIn,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
